/* eslint-disable react/no-danger */
import { getClientsideHref } from "utils";
import { useAppState, withAppState } from "store/AppState";
import { withSession } from "store/Session";
import withDialogs from "hocs/withDialogs";
import withToasts from "hocs/withToasts";
import withFacebookPixel from "next-fbq";
import { DefaultSeo } from "next-seo";
import { useEffect, useRef } from "react";
import { withTheme } from "theme";
import NProgress from "nprogress";
import Router, { useRouter } from "next/router";
import { withI18n } from "i18n";

import TransparentLayout from "layouts/TransparentLayout";
import useLatestVersion from "hooks/useLatestVersion";
import createSeoInjector from "hocs/createSeoInjector";
import createDefaultSeoConfig from "seo/app";

import "nprogress/nprogress.css";
import "global.css";

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeError", () => NProgress.done());
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());

Router.events.on("hashChangeComplete", () => {
  const { hash } = getClientsideHref();
  if (hash) {
    const element = document.getElementById(hash);
    if (element) element.scrollIntoView({ behavior: "smooth", block: "center" });
  }
});

const App = ({ Component, pageProps }) => {
  const { state, setState } = useAppState();
  const didMountRef = useRef();
  const router = useRouter();
  useLatestVersion();

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) jssStyles.parentNode.removeChild(jssStyles);

    const { query } = getClientsideHref();
    if (query.ref) {
      localStorage.setItem("referral", query.ref);
    }
  }, []);
  
  useEffect(() => {
    if (didMountRef.current && !state.hasHistory) {
      setState({ hasHistory: true });
    } else {
      didMountRef.current = true;
    }
  }, [router.pathname]);

  const Layout = Component.Layout || TransparentLayout;

  return (
    <>
      <meta
        name="viewport"
        content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
      />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

const withFBQ = withFacebookPixel("205307724076009", Router);
const withSeo = createSeoInjector(createDefaultSeoConfig, DefaultSeo);

const hocs = [
  withFBQ,
  withDialogs,
  withTheme,
  withSession,
  withAppState,
  withI18n,
  withToasts,
  withSeo,
];

const wrappedApp = hocs.reduce((app, wrapper) => wrapper(app), App);

export default wrappedApp;
