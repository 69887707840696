import createSeoConfig from "./createSeoConfig";

const createDefaultSeoConfig = () => createSeoConfig({
  title: "Trivias y Premios En Vivo",
  description: "Ganá el premio del dia respondiendo preguntas",
  images: [
    {
      width: 512,
      height: 512,
      alt: "logo",
    },
  ],
});

export default createDefaultSeoConfig;
